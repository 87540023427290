@font-face {
  font-family: "Poppins";
  //src: url("../Layout/Assets/fonts/icons/Flaticon.eot");
  src: url("../Layout/Assets/fonts/pxiByp8kv8JHgFVrLGT9V1g.woff") format("woff"),
  		url("../Layout/Assets/fonts/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  /*src: url("../Layout/Assets/fonts/icons/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../Layout/Assets/fonts/icons/Flaticon.woff2") format("woff2"),
       url("../Layout/Assets/fonts/icons/Flaticon.woff") format("woff"),
       url("../Layout/Assets/fonts/icons/Flaticon.ttf") format("truetype"),
       url("../Layout/Assets/fonts/icons/Flaticon.svg#Flaticon") format("svg");*/
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Poppins";
    src: url("../Layout/Assets/fonts/pxiByp8kv8JHgFVrLGT9V1g.woff#Poppins") format("woff");
  }
}

*{
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
}
.text-black{
  color:black !important;
}