$azul-indicator:#4394D0;
.custom-react-select{
	& > div > div{
		padding:0px !important;
	}
	& > div{
		border-color: #fff0 !important;
		border-radius: 0px !important;
		min-height:0px !important;
		box-shadow: 0 0 0 1px #FFF0;
	}
	& > div:first-of-type > div > div{
		padding: 0px !important;
	}
	& > div:nth-child(3n){	
		margin-top: 0px;
		width: 101%;
		left: -1px;
		border: 1px solid #ced4da!important;
	}
	& svg, {
		color: $azul-indicator;
	}
}
span[class*='-indicatorSeparator']{
	background-color: hsla(0, 0%, 80%, 0);
}
.form-group > div[class*='-container']  div[class*='-control']{
	border: 1px solid #ced4da;
	&.react-select__control--is-focused{
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 .2rem rgba(0,123,255,.25) !important;
	}
	& svg, {
		color: $azul-indicator;
	}
}


.icon-menu-movil a{
	font-family: Poppins;
	font-weight: 400;
}