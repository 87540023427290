.input-file-custom{
	position: relative;
	cursor: pointer;
	& > input{
		width: 100%;
		height: 220px;
		border: 0px solid red;
		opacity: 0.01;
		cursor: pointer;
	}
	&:after{
		font-family: "Flaticon";
		content: $font-Flaticon-usuario;
		width: 100%;
		height: 220px;
		position: absolute;
		left: 0;
		border: 1px solid;
		border-radius: 0%;
		font-size: 40px;
		padding: 25px;
		top: 0;
	}
	&.marcas:after{
		content: $font-Flaticon-capa;
	}
	&.img:after{
		font: normal normal normal 45px/2.5 FontAwesome;
		content: "\f03e";
	}
	
	&:before{
		font-family: "Flaticon";
		content: $font-Flaticon-mas;
		width: 20px;
		height: 20px;
		right: 50%;
		position: absolute;
		//right: 7px;
		font-size: 35px;
		bottom: 40px;
		font-weight: 900;
		top: 100px
	}
	&:hover{
		background: #c6c6c66e;
	    border-radius: 0%;
	    color: black;
	};
	& > .preview {
		width: 100%;
		max-height: 100%;
		height: 100%;
		position: absolute;
		border-radius: 0%;
		object-fit: cover;
		left: 0;
		z-index: -1;
		top: 0;
	}
	&.on:after, &.on:before{
		content: '';
	}
	& .invalid-feedback{
		position: absolute;
		top: -40px;
		margin: auto;
		width: 100%;
		left: auto;
		right: auto;
	}
}

.input-file-radius{
	position: relative;
	cursor: pointer;
	& > input{
		width: 120px;
		border: 0px solid red;
		opacity: 0.01;
		cursor: pointer;
		height: 120px;
	}
	&:after{
		font-family: "Flaticon";
		content: $font-Flaticon-usuario;
		width: 120px;
		height: 120px;
		position: absolute;
		left: 0;
		border: 1px solid;
		border-radius: 50%;
		font-size: 40px;
		padding: 25px;
		top: 0;
	}
	&.marcas:after{
		content: $font-Flaticon-capa;
	}
	&:before{
		font-family: 'Font Awesome 5 Free';
		content: "\f030";
		width: 35px;
		height: 35px;
		position: absolute;
		right: -7px;
		bottom: 1px;
		font-weight: 900;
		background: red;
		border-radius: 50%;
		color: white;
		font-size: 15px;
		padding: 6px;
		z-index: 2;
		opacity: 0.8;
	}
	&:hover{
		background: #c6c6c66e;
	    border-radius: 50%;
	    color: black;
	};
	& > .preview {
		width: 100%;
		max-height: 100%;
		height: 100%;
		position: absolute;
		border-radius: 50%;
		object-fit: cover;
		left: 0;
		z-index: 1;
		top: 0;
	}
	&.on:after{
		content: '';
	}
	& .invalid-feedback{
		position: absolute;
		top: -40px;
		margin: auto;
		width: 100%;
		left: auto;
		right: auto;
	}

}
.edit-img{
	width: 35px;
	height: 35px;
	position: absolute;
	left: -7px;
	bottom: 1px;
	font-weight: 900;
	background: red;
	border-radius: 50%;
	color: white;
	font-size: 15px;
	padding: 10px;
	z-index: 2;
	opacity: 0.8;
}