$color-blue: #191F28;
$burger: white;
$color-black: black;
$color-blue-0:#D1F0E9;
$color-blue-0-hover:#A6EADB5E;
$color-blue-1:#65A3AF;
$color-red:#d8312e;

$color-gray:#8a8a8a;

$color-red-btn:#d62527;
$color-red-btn-border-hover:#991c1e;
$color-red-btn-bg-hover:#bb0b0d;

$small:576px;
$medium:768px;
$large:992px;
$x-large:1200px;
$xx-large:1400px;

@mixin extra-small {
  @media (min-width: 0px) {
    @content;
  }
}
@mixin small {
  @media (min-width: #{$small}) {
    @content;
  }
}
@mixin medium {
  @media (min-width: #{$medium}) {
    @content;
  }
}
@mixin large {
  @media (min-width: #{$large}) {
    @content;
  }
}
@mixin x-large {
  @media (min-width: #{$x-large}) {
    @content;
  }
}
@mixin xx-large {
  @media (min-width: #{$xx-large}) {
    @content;
  }
}
