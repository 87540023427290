#root{
	height: 100vh;
}

@import './fontSitie';
@import './colors';
@import './auth';
@import './sidebar-left';
@import './table';
@import './toggle-switch';
@import './filtres-dashboard';
@import './timepiker';
@import './glyphicons';
@import './flaticon';
@import 'font-awesome';
@import '@fortawesome/fontawesome-free';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/solid';
@import './react-select';
@import './input-file';
@import './validations';
@import './navs';
@import './rate';
@import "bootstrap";
@import "./button";
@import "./input";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import "./juez";
@import "./reviews";


/*
	Siempre de ultimo
*/
@import './global';
