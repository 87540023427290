.custom-btn{
	border-radius: 6px;
	&-red{
		background-color: $color-red-btn !important;
		border-color: $color-red-btn !important;
		color: white !important;
		-webkit-box-shadow: 1px 1px 3px -1px black;
		-moz-box-shadow: 1px 1px 3px -1px black;
		box-shadow: 1px 1px 3px -1px black;
		&:hover{
			background-color:$color-red-btn-bg-hover !important;
			border-color:$color-red-btn-border-hover !important;
			color: white !important;
		}
	}
}