.img-balck:before{
	background-color: rgba(0, 0, 0, 0.5);
	content: ' ';
	position: absolute;
	display: block;
	width: 98.8%;
	height: 100%;
	border-radius: 10px;
}
.bg-black{
	background-color: black; 
}
.fa-red{
	color: $color-red; 
}
*:not(.rs-rate *){
	font-size: 13px;
}
.contador-style > span{
	font-size: 3rem !important;
}
input.form-control{
	background-color: rgba(250,250,250,1);
	outline: none;
	border-radius: 0;
	width: 100%;
	color:#222222;
	font-size: 1.14rem;
	box-shadow: none;
}
.borde{
	padding: 2.57em !important;
	border: 1px solid #e1e1e1;
}
.h-30rem{
	height: 30rem;
}
.invertir-blancos{
	filter: brightness(60%) saturate(200%);
}
.medalla {
	position: absolute;
	width: 45px;
	right: 6px;
	top: 6px;
	border-radius: 50%;
	padding: 5px;
	background:red;
	&-page{
		position: relative;
		//width: 75px;
    height: 68px;
    width: 68px;
		top: -30px;
		border-radius: 50%;
		padding: 5px;
		margin: auto;
	}
	&-icon-white{
		width: 20px;
		margin-left: 5px;
		margin-right: 5px;
		& .cls-1{
			fill:white;
		}
	}
	&.bronze,&-page.bronze {
		background:#cd8032;
		& .cls-1{
			fill:#9b5c23;
		}
    & #Trazado_185{
      fill: #9b5c23;
    }
	}
	&.silver,&-page.silver {
		background:#bbbdbf;
		.cls-1{
			fill:#6d6e70;
		}
    & #Trazado_185{
      fill: #6d6e70;
    }
	}
	&.gold,&-page.gold  {
		background:#f4cc6a;
		& .cls-1{
			fill:#c68f30;;
		}
    & #Trazado_185{
      fill: #c68f30;
    }
	}
	&.aling-bottom {
		bottom: 0;
		top: auto;
		right: 2rem;
	}
}
.p-img{
	position: absolute;
	top: 5rem;
	left: 0;
	right: 0;
}
.card, .card-img, .card-img-top {
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.rs-auto-complete-item {
  display: block;
  padding: 8px 0px !important;
}
.rs-anim-fade.rs-anim-in.rs-picker-menu.placement-bottom-start{
  margin-top: 1px !important;
}

// .rs-rate-md .rs-rate-character {
//   width: 4px !important;
//   height: 16px !important;
// }
.rs-rate-character {
    margin-right: 0px !important;
}
.rs-rate-xs,
.rs-rate-xs .rs-rate-character {
	top: 1px;
    font-size: 14px !important;
}
.rs-rate-xs .rs-rate-character {
    width: 14px !important;
}

.card, .card-img, .card-img-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.shop-filters-sticky{
  padding-top: 5.8rem !important;
  margin-top: -5.5rem;
  background-color: white;
  z-index: 1018 !important;
}
.widget-community{

	& .slick-list > .slick-track{
	  //height: 33rem;
	}
	& .slick-item-main{
		padding: 0px 0px;
	}
	
	& .slick-slide.slick-active  .item-user{
	  background-color: white;
	  //transform: scale(1.1);
	  //transform-origin: 100px 185px 0px;
	  z-index: 1;
	  //border:1px solid red;
	  //margin: 0px 16rem;
	}
	& .slick-slide.slick-active .py-4{
		background-color: white;
	}
	& .slick-slide.slick-active.slick-center.slick-current{
		background-color: white;
		//transform: scale(1.1);
		//transform-origin: 100px 185px 0px;
		transform: scale(1);
		transition: all .2s ease-in;
		z-index: 1;
		position: relative;
		//margin: 0px 16rem;
	}
	& .slick-slide.slick-active.slick-center.slick-current .item-user{
		border:1px solid red;
	}
	& .slick-slide:not(.slick-current) {
	  /* Make slides other than the current one translucent */
	  opacity: 0.8;
	  transform: scale(0.9);
	  transition: all .2s ease-in-out;
	}
	& .kpi-users{
		& .title{
			font-size: 10px !important;
		}
		
	}
	& .img-responsive.h-100{
		font-size: 10px !important;
	}
}

.slick-next {
  right: -4px;
}
.slick-prev {
  left: -4px;
}

.slick-prev, .slick-next{
  width: auto !important;
  height: auto !important;
  z-index: 1 !important;
  &::before{
    content: '' !important;
  }
}
.title-line-bg{
  position: relative;
  & span {
    background-color: white;
    padding: 0px 10px;
  }
  &::before{
    content: '' !important;
    width: 100%;
    height: 4px;
    background-color: black;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 41%;
    z-index: -1;
  }
}

.rate-item{
  top: 1px;
  position: relative;
}
.infinite-scroll-component.row.justify-content-center.d-flex.align-items-stretch{
  overflow: hidden !important;
}