.filtres-dashboard{
	& .input-group-sm > .input-group-prepend > .input-group-text {
	    font-size: 9.5px;
	    font-weight: 700px;
	    padding: .10rem .2rem;
	    width: 6rem;
	    height: 100%;
	    border-radius: 0px;
	}
	& .input-group-sm > .form-control, & .form-control > .form-control {
		font-size: 9.5px;
	    font-weight: 700px;
		height: 1.9rem;
		border-radius: 0px;
		padding-top: 0;
		padding-bottom: 0;

		& img{
			height: 15px;
			width: 15px;
		}
	}
	& .custom-react-select > div[class*='css-']:first-of-type{
		height: 100%;
	}
	& .btn{
		font-size: 9.5px;
	    font-weight: 700px;
		height: 100%;
		border-radius: 0px;
	}
	& .btn-primary {
	    background-color: $color-blue-1;
	    border-color: $color-blue-1;
		&:hover {
		    background-color: #568C97;
		    border-color: $color-blue-1;
		}
		&:not(:disabled):not(.disabled):active{
			background-color: #3F666F;;
			border-color: $color-blue-1;
		}
	}
	& .form-control > .form-control {
		border: 0px;
		border-radius: 0px;
		height: 100%;
	}
	& .input-group-date-time{
		padding:0px;
	}
}