input[type="checkbox"].is-invalid {
    width: 15px;
	margin-right: 5px;
}
.form-check-invalid {
	flex-wrap: wrap;
	& > .invalid-feedback{
		margin-top: .0rem;
		margin-bottom: .50rem;
	}
}