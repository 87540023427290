.nav-top{
	& .container > .mr-auto {
		margin-left: -4px;
	}
	& .container > .ml-auto {
		margin-right: -4px;
	}
	& .nav-link {
		font-family: Lato;
		font-size: 14px;
    	font-weight: 400;
    	padding-top: 4.5px !important;
		padding-bottom: 5.5px !important;
	}
	& .border-left-1 {
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
}
.navbar-top{
	margin:auto -15px;
	padding-top: 1.5em;
	padding-bottom: 1.5em;
	border-bottom: 1px solid #dee2e6;
	a {
		font-size: 13px;
		font-weight: 500;
		line-height: 16px;
		text-transform: uppercase;
		color:$color-black;
	}
	& .header-logo{
		max-width: 120px;

	}
	& .navbar-brand{
		padding:0px;
		margin-right:0px;
	}
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  border-color: black;
} 
.btn-back{
	background:white;
	border-bottom-right-radius: 15%;
	border-top-right-radius: 0%;
	height: 45px;
	width: 45px;
	border: solid 0px #dee2e6;
	border-left:0px;
	color: red !important;
	//border-left: solid 0px #dee2e6;
	padding: 8px 6px 6px 13px;
	//padding: 7px 0px 8px 13px;
	text-align: left;
	//border-radius: 50%;
	font-size: 30px;
	box-shadow: 1px 1px 3px -1px #00000082;
	//border-top: 0px;
}
.btn-right-top{
	background:white;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 0px;
	border: solid 0px #dee2e6;
	border-right: 0px;
	color:white !important;
	padding: 13px 6px 13px 6px;
	text-align: left;
	display: inline-block;
	background-color: red;
	box-shadow: 1px 1px 3px -1px black;
}
.form-search {
	border-bottom-left-radius: 5px;
	box-shadow: -1px 1px 3px -1px #00000082;
	max-width: 45px !important;
	
	height: 45px;
	right: 0;
	position: absolute !important;
	transition: max-width 0.4s;
	& > input {
		padding-left: 0px;
		padding-right: 0px;
		transition: padding-right 0.4s,padding-left 0.4s;
	}
	&.open{
		max-width: 100% !important;
	}
	&.open > input{
		padding-left: 15px;
		padding-right: 15px;
	}
	& .form-control, & .input-group-text{
		margin-bottom: 0.38rem;
		background-color: #fff;
		border:none;
	}
	& .form-control:focus{
		border:none;
		box-shadow:none;
	}
}

.pulse {
	box-shadow: 0 0 0 black;
	animation: pulse 2s infinite;
	&::hover {
		animation: none;
	}
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(216, 49, 46, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(216, 49, 46, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(216, 49, 46, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(216, 49, 46, 0.4);
    box-shadow: 0 0 0 0 rgba(216, 49, 46, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(216, 49, 46, 0);
      box-shadow: 0 0 0 10px rgba(216, 49, 46, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(216, 49, 46, 0);
      box-shadow: 0 0 0 0 rgba(216, 49, 46, 0);
  }
}

.bar-footer{
	box-shadow: 0px 8px 16px -2px black;
	-webkit-box-shadow: 0px 8px 16px -2px black;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	.start-menu{
		//padding-top: 35px !important;
		padding-top: 48px !important;
	}
	& small{
		font-size: 12px;
	}
	& .start-vote{
		//top: -29px;
		top: -16px;
		padding: 0px 0px;
		border-radius: 50%;
		color: white;
		font-size: 28px;
		position: absolute;
		background-color: red;
		// height: 56px;
		// width: 56px;
		height: 65px;
		width: 65px;
		margin-left: -5px;
		box-shadow: 0px 3px 15px -6px black;
	}
}
.mt-negative-4{
	margin-top: -4.3rem;
}

.rs-panel-body, .rs-panel-header {
  padding: 1.25rem !important;
}

