/*
Flaticon icon font: Flaticon
Creation date: 10/08/2019 14:25
*/
//Para eliminar las imagenes que son iconos
@font-face {
  font-family: "flaticon";
  src: url("../Layout/Assets/fonts/icons/Flaticon.eot");
  src: url("../Layout/Assets/fonts/icons/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../Layout/Assets/fonts/icons/Flaticon.woff2") format("woff2"),
       url("../Layout/Assets/fonts/icons/Flaticon.woff") format("woff"),
       url("../Layout/Assets/fonts/icons/Flaticon.ttf") format("truetype"),
       url("../Layout/Assets/fonts/icons/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../Layout/Assets/fonts/icons/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before{
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-reproductor-de-musica-play:before { content: "\f100"; }
.flaticon-reproductor-de-musica-play-1:before { content: "\f101"; }
.flaticon-usuario:before { content: "\f102"; }
.flaticon-opcion-de-listado:before { content: "\f103"; }
.flaticon-grupo:before { content: "\f104"; }
.flaticon-velocimetro:before { content: "\f105"; }
.flaticon-editar:before { content: "\f106"; }
.flaticon-forma-de-sobre-cerrado-negro:before { content: "\f107"; }
.flaticon-capa:before { content: "\f108"; }
.flaticon-calendario:before { content: "\f109"; }
.flaticon-comprobado:before { content: "\f10a"; }
.flaticon-basura:before { content: "\f10b"; }
.flaticon-insecto:before { content: "\f10c"; }
.flaticon-descargar-a-la-unidad-de-almacenamiento:before { content: "\f10d"; }
.flaticon-flecha:before { content: "\f10e"; }
.flaticon-flecha-1:before { content: "\f10f"; }
.flaticon-busqueda:before { content: "\f110"; }
.flaticon-opcion-de-carga-de-almacenamiento-en-la-nube:before { content: "\f111"; }
.flaticon-herramienta-de-filtro-de-forma-negra:before { content: "\f112"; }
.flaticon-documento:before { content: "\f113"; }
.flaticon-diente:before { content: "\f114"; }
.flaticon-mas:before { content: "\f115"; }

/* Variables con el codifo de la feunte para otros usos*/ 
$font-Flaticon-reproductor-de-musica-play: "\f100";
$font-Flaticon-reproductor-de-musica-play-1: "\f101";
$font-Flaticon-usuario: "\f102";
$font-Flaticon-opcion-de-listado: "\f103";
$font-Flaticon-grupo: "\f104";
$font-Flaticon-velocimetro: "\f105";
$font-Flaticon-editar: "\f106";
$font-Flaticon-forma-de-sobre-cerrado-negro: "\f107";
$font-Flaticon-capa: "\f108";
$font-Flaticon-calendario: "\f109";
$font-Flaticon-comprobado: "\f10a";
$font-Flaticon-basura: "\f10b";
$font-Flaticon-insecto: "\f10c";
$font-Flaticon-descargar-a-la-unidad-de-almacenamiento: "\f10d";
$font-Flaticon-flecha: "\f10e";
$font-Flaticon-flecha-1: "\f10f";
$font-Flaticon-busqueda: "\f110";
$font-Flaticon-opcion-de-carga-de-almacenamiento-en-la-nube: "\f111";
$font-Flaticon-herramienta-de-filtro-de-forma-negra: "\f112";
$font-Flaticon-documento: "\f113";
$font-Flaticon-diente: "\f114";
$font-Flaticon-mas: "\f115";