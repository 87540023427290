.switch {
	position: relative;
	display: inline-block;
	height: 16px;
	width: 41px;
}

.switch input { 
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #CBCBCB;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before,.disable-user:before,.enable-user:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	background-color: red;
	-webkit-transition: .4s;
	transition: .4s;
	-webkit-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
	-moz-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
	box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
}

input:checked + .slider {
	background-color: #CBCBCB;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(28px);
	-ms-transform: translateX(28px);
	transform: translateX(28px);
	background-color: #279A39;
}
.enable-user:before{
	background-color: #279A39;
}
/* Rounded sliders */
.slider.round {
	border-radius: 34px;
	height: 15px;
	width: 40px;
}

.slider.round:before,.disable-user:before,.enable-user:before {
	border-radius: 50%;
}