.custom-input, .form-control.is-invalid{
	border-radius: 6px !important;
	height: calc(1.5em + 1.75rem + 2px);
	border-color:$color-gray;
	font-size: 12px !important;
}
.form-control.is-invalid{
	border-color:$color-red;
}
.custom-select{
	height: 41px;
}
.input-super-search{
  & input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-red;
    opacity: 1; /* Firefox */
  }
  
  & input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-red;
  }
  
  & input::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-red;
  }
  border: 1px solid $color-red !important;
  & .fa-search{
    color:$color-red
  }
}