
.rate {
    & {
        float: left;
        height: 46px;
        padding: 0 10px;
        position:relative;
    }
    &:not(:checked) > input {
        position:absolute;
        //top:-9999px;
        display: none;
    }
    &:not(:checked) > label {
        float:right;
        width:1em;
        overflow:hidden;
        white-space:nowrap;
        cursor:pointer;
        font-size:30px;
        color:#ccc;
    }
    &:not(:checked) > label:before {
        content: '★ ';
    }
    & > input:checked ~ label {
        color: #ffc700;    
    }
    &:not(:checked) > label:hover,
    &:not(:checked) > label:hover ~ label {
        color: #deb217;  
    }
    & > input:checked + label:hover,
    & > input:checked + label:hover ~ label,
    & > input:checked ~ label:hover,
    & > input:checked ~ label:hover ~ label,
    & > label:hover ~ input:checked ~ label {
        color: #c59b08;
    }
    & .form-control.is-invalid ~ .invalid-feedback{
        display: block;
        top: -12px;
        position: absolute;
        width: 16rem;
    }
}
.star-ratings {
    color: #989898;
    position: relative;
    margin: 0;
    padding: 0;
    display: row;
    height: 19px;
    min-width: 100.50px;
    max-width: 100.50px;
    text-decoration: none !important;
    letter-spacing:0px;
    //margin: auto;
    & .fill-ratings {
        color:  #ffc700;
        padding: 0;
        margin: 0;
        position: absolute;
        display:inline-block;
        z-index: 1;
        top: 0;
        left: 0;
        overflow: hidden;
        // Allows us to grab the width of the span elements
        & div {
            top: 0;
            left: 0;
            font-size: 1.0rem  !important;
            position:relative;
            display:inline-block;
            width: 100.50px;
        }
    }
    & .empty-ratings {
        padding: 0;
        margin: 0;
        position:absolute;
        z-index: 0;
        top: 0;
        left: 0;
        & div {
            top: 0;
            left: 0;
            font-size: 1.0rem  !important;
            position: relative;
            display: inline-block;
            width: 100.50px;
        }
    }
}
.show-ponits{
    float: right;
    box-shadow: 1px 1px 3px 1px #e1e1e1;
    border-radius: 5rem;
    padding: 2px 10px 2px 10px;
}

.google-login-ctn {
    display: flex;
    justify-content: center;
}

.facebook-login-btn {
    border: 1px solid #4C69BA;
    width: 215px;
    height: 30px;
    background: #4C69BA;
    color: white;
}
