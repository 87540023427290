.table-dashboard{
	&.table-striped tbody tr:nth-of-type(2n+1) {
	    background-color: $color-blue-0;
	}
	&.table-hover tbody tr:hover {
	    color: #212529;
	    background-color: $color-blue-0-hover;
	}
	& thead tr {
		background-color: $color-blue-1;
	}
	& td, & th {
	    border-top: 1px solid $color-blue-0;
	}
	& thead th {
		border-top: 1px solid $color-blue-1;
		border-bottom: 2px solid $color-blue-1;
		font-weight: 400;
		font-size: 11px !important;
	}
	& tbody td, & tbody th{
		font-size: 12px;
	}
}
.table .img-circle {
	width: 25px;
	height: 25px;
	border-radius: 50%;

	object-fit: cover;
	object-position: center right;
}
.triangle {
	& , &:before, &:after{
		width: 8px;
		height: 8px;
	}
	overflow: hidden;
	position: relative;
	margin: 4px 0px 0px -5px;
	border-radius: 20%;
	transform:
		translateY(50%)
		rotate(30deg)
		skewY(30deg)
		scaleX(.866);
	cursor: pointer;
	float: left;
	left: 49px;

	&:before, &:after {
		position: absolute;
		background: white;
		pointer-events: auto;
		content: '';
	}
	&:before {
		border-radius: 20% 20% 20% 53%;
		transform: 
			scaleX(1.155)
			skewY(-30deg)
			rotate(-30deg)
			translateY(-42.3%) 
			skewX(30deg)
			scaleY(.866)
			translateX(-24%);
	}
	&:after {
		border-radius: 20% 20% 53% 20%;
		transform: 
			scaleX(1.155)
			skewY(-30deg)
			rotate(-30deg)
			translateY(-42.3%)
			skewX(-30deg)
			scaleY(.866)
			translateX(24%);
	}
	&.desc{
		transform:
			translateY(15%)
			rotate(-30deg)
			skewY(30deg)
			scaleX(0.866)!important;
		margin-right: -10px !important;
	}
}

