$width-sidebar:18rem;
$width-close: 5rem;
.black{
	position: absolute;
	width: 100%;
	background-color: black;
	height: 65px;
	left: 0;
	z-index: -1;
}
.bar-sidebar-left{
	width: 20px;
	display: block;
	cursor: pointer;
	float: right;
	margin: 12px 0px 0px 0px;
	height: 18px;
	&:after,&:before{
		content: "";
		width: 100%;
		height: 2px;
		background-color:$burger;
		display: flex;
		border-radius: 10px;
		margin-bottom: 4px;
		margin-top: 4px;
	}
}
.sidebar{
	
	&-left{
		& .icon-brand{
			display: none;
		}
		& .img-fluid{
			width: 130px;
		}
		& .sidebar-icon{
		    width: 17px;
		    margin-right: 5px;
		    margin-bottom: 5px;
		}
		& .avartar-user{
			//width: 70%;
			width: 150px;
			height: 150px;
			margin-top: 25px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center right;
		}
		& {
			height: 100%;
			width: $width-sidebar;
			position: fixed;
			z-index: 1;
			top: 0;
			left: 0;
			background-color: $color-blue;
			overflow-x: hidden;
			padding-top: 0px;
			padding-left: 20px;
			padding-right: 35px;
			transition: width 0.5s;
		}
		& .li-link, li.frist-brand{
			padding: 15px 0px 15px 0px;
			border-bottom:0px;
		}
		& .li-link {
			border-bottom: 0.1px solid white;
			cursor: pointer;
			font-size: 15px;
			color: #aeaeae;
			display: block;

			&:first-of-type{
				border-top: 0px solid;
			}
		}
		& .li-link {
			& li {
				color: #f1f1f1;
				border-bottom: 0px solid white;
				padding: 15px 0px 0px 0px;
			}
		}
		& li:hover {
			color: #f1f1f1;
		}
		& a:hover {
			color: #f1f1f1;
			text-decoration: none;
		}
		& ul{
			list-style: none;
		}
	}
	&-close{
		width: $width-close;
		padding-left: 15px;
		padding-right: 15px;
		transition: width 0.5s;
		&  .black{
			height: 45px;
		}
		& .bar-sidebar-left{
			margin: auto;
			float: none;
		}
		& .sidebar-label{
			display: none;
		}
		& li{
			text-align: center;
		}
		& .icon-brand{
			display: block;
			width: 30px;
			margin: auto;
			margin-bottom: 15px;
			margin-top: 25px;
		}
		& .icon-brand-open{
			display: none;
		}
		& .avartar-user{
			margin-top: 0px;
			width: 45px;
			height: 45px;
		}
	}
}
.layout-main, .layout-footer{
  margin-left: calc(#{$width-sidebar} - 1rem);
  transition: margin-left 0.5s;
}
.layout-sidebar-close{
	margin-left: calc(#{$width-close} - 1rem);
}