.widget-the-top-judges{
    @include medium {
        & .row  .col-auto {
            // padding: 20px 0px !important;
            width: 20%;
        }
    }
}
.main-quantities{
    @include medium {
        & > .col-auto {
            // padding: 20px 0px !important;
            width: 20%;
        }
    }
}
.banner-juez{
    &-img{
        position: absolute;
        z-index: -1;
        //top: -3rem;
        left: 0px;
        width: 100%;
        bottom: 0px;
        height: 100%;
        object-fit: cover;
    }
    @include x-large {
        & {
            height: 40vh;
            padding: 0rem 5rem;
        }
        & p{
            font-size: 1.5rem;
        }
        & h1{
            font-size: 6rem;
        }
    }
        
}